<template>
  <div class="w-full">
    <SuccessButton :disabled="!$atividades.permissoes.includes(14)" @func="modalCadastroFrase = true"></SuccessButton>
    <SideBar
      size="small"
      title="Cadastrar frase"
      @hide="modalCadastroFrase = false"
      :active="modalCadastroFrase"
      @success="submitForm()"
    >
      <div slot="body">
        <vs-row class="mb-4" vs-type="flex" vs-w="12">
          <vs-col vs-w="12">
            <div style="display:inline-block" class=" w-full">
              <vs-input
                class="inputx w-full"
                label-placeholder="Frase"
                name="nome"
                v-validate="'required'"
                v-model="frase.frase"
              />
              <span
                class="ml-3 text-danger text-sm"
                v-show="errors.has('nome')"
              >
                {{ $validators.empty }}</span
              >
            </div>
          </vs-col>
          <vs-col vs-w="12">
            <div style="display:inline-block;margin-right:20px;">
              <label style="color: black; font-size: 13px;">Tipo da frase</label
              ><br />
              <el-select
                v-validate="'required'"
                class="w-full"
                name="tipo"
                v-model="frase.tipo"
                :popper-append-to-body="false"
              >
                <el-option value="1" label="Recomendacao" />
                <el-option value="2" label="Nao executada" />
                <el-option value="3" label="Declinio" />
              </el-select>
              <vs-row>
                <span class="text-danger text-sm" v-show="errors.has('tipo')">
                  {{ $validators.empty }}</span
                >
              </vs-row>
            </div>
          </vs-col>
        </vs-row>
      </div>
    </SideBar>
  </div>
</template>

<script>
import components from "@/components/default/exports.js";

export default {
  name: "ModalCadastroFrase",
  data() {
    return {
      frase: {},
      modalCadastroFrase: false,
      error: false
    };
  },
  methods: {
    async submitForm() {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.cadastrarFrase();
          } else {
            this.$vs.notify({
              title: "Preencha todos os campos",
              text: "Os campos não devem ser vazios!",
              color: "danger"
            });
          }
        });
      });
    },
    async cadastrarFrase() {
      this.$vs.loading();
      try {
        const res = await this.$http.post("frase", this.frase);
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "cadastrar",
          setor: "comercial",
          ip: window.localStorage.getItem("ip"),
          texto: "Cadastro da frase N°" + res.id
        }
        await this.$logger(logData)
        this.fornecedor = {};
        this.$vs.notify(this.$notify.Success);
        this.modalCadastroFrase = false;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$emit("update");
        this.$vs.loading.close();
      }
    }
  },
  components: components
};
</script>
