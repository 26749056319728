<template>
  <div>
    <EditButton :disabled="!$atividades.permissoes.includes(14)" @func="modalEditarFrase = true"></EditButton>

    <SideBar
      title="Editar frase"
      size="small"
      @hide="cancelar"
      textSucces="Atualizar"
      icon="edit"
      :disabled="!$atividades.permissoes.includes(15)"
      :item="frase"
      @excluir="excluirFrase()"
      @status="mudarStatusFrase()"
      :active="modalEditarFrase"
      @success="submitForm()"
    >
      <div slot="body">
        <vs-row class="mb-4" vs-type="flex" vs-w="12">
          <vs-col vs-w="12">
            <div style="display:inline-block" class=" w-full">
              <vs-input
                class="inputx w-full"
                v-validate="'required'"
                name="nome"
                label-placeholder="Frase"
                v-model="frase.frase"
              />
              <span
                class="ml-3 text-danger text-sm"
                v-show="errors.has('nome')"
              >
                {{ $validators.empty }}</span
              >
            </div>
          </vs-col>
          <vs-col vs-w="12">
            <div style="display:inline-block;margin-right:20px;">
              <label style="color: black; font-size: 13px;">Tipo da frase</label
              ><br />
              <el-select
                v-validate="'required'"
                class="w-full"
                name="tipo"
                v-model="frase.tipo"
                :popper-append-to-body="false"
              >
                <el-option value="1" label="Recomendacao" />
                <el-option value="2" label="Nao executada" />
                <el-option value="3" label="Declinio" />
              </el-select>
              <vs-row>
                <span class="text-danger text-sm" v-show="errors.has('tipo')">
                  {{ $validators.empty }}</span
                >
              </vs-row>
            </div>
            <span class="text-danger text-sm" v-show="errors.has('tipo')">{{
              $validators.select
            }}</span>
          </vs-col>
        </vs-row>
      </div>
    </SideBar>
  </div>
</template>

<script>
import components from "@/components/default/exports.js";

export default {
  name: "modalEditarFrase",
  props: { frase: Object, type: String },
  data() {
    return {
      modalEditarFrase: false,
      error: false,
      originalData: {}
    };
  },
  mounted() {
    this._beforeEditingCache = Object.assign({}, this.frase);
    this.originalData = this.frase;
  },
  methods: {
    async submitForm() {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.editarFrase();
          } else {
            this.$vs.notify({
              title: "Preencha todos os campos",
              text: "Os campos não devem ser vazios!",
              color: "danger"
            });
          }
        });
      });
    },
    async editarFrase() {
      this.$vs.loading();
      try {
        const res = await this.$http.put(`frase/${this.frase.id}`, this.frase);
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "update",
          setor: "comercial",
          ip: window.localStorage.getItem("ip"),
          texto: "Edição da frase N°" + res.id
        }
        await this.$logger(logData)
        this.$vs.notify(this.$notify.Success);
        this.modalEditarFrase = false;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.$vs.loading.close();
      }
    },
    async excluirFrase() {
      this.$vs.loading();
      try {
        await this.$http.delete(`frase/${this.frase.id}`);
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "excluir",
          setor: "comercial",
          ip: window.localStorage.getItem("ip"),
          texto: "Exclusão da frase N°" + this.frase.id
        }
        await this.$logger(logData)
        this.$vs.notify(this.$notify.Success);
        this.modalEditarFrase = false;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.$vs.loading.close();
        this.$emit("update");
      }
    },
    async mudarStatusFrase() {
      this.$vs.loading();
      try {
        const res = await this.$http.put(`frase/${this.frase.id}`, {
          lixeira: !this.frase.lixeira
        });
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "atualizar",
          setor: "comercial",
          ip: window.localStorage.getItem("ip"),
          texto: "Mudança do status da frase N°" + res.id
        }
        await this.$logger(logData)
        this.$vs.notify(this.$notify.Success);
        this.modalEditarFrase = false;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.$vs.loading.close();
        this.$emit("update");
      }
    },
    cancelar() {
      Object.assign(this.frase, this._beforeEditingCache);
      this.originalData = this._beforeEditingCache = null;
      this.modalEditarFrase = false;
    }
  },
  components: components
};
</script>

<style></style>
