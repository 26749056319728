<template>
  <div class="w-full">
    <div ref="header flex">
      <vs-row class="mb-4" vs-type="flex" vs-w="12">
        <vs-col vs-justify="left" vs-align="left" vs-w="10">
          <h4 style="color: #B4AA99">Frases usadas no aplicativo e sistema</h4>
        </vs-col>
        <vs-col vs-justify="left" class="flex" vs-align="left" vs-w="2">
          <modal-cadastro-frase class="w-full" @update="getFrases" />
        </vs-col>
      </vs-row>
    </div>



      <vs-tabs position="left" alignment="fixed" class="tabs-shadow-none" id="profile-tabs">
        <!-- GENERAL -->
        <vs-tab  icon-pack="feather" icon="icon-thumbs-up" label="Recomendacao" class="w-full">
          <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0 w-full">
            <vx-card no-shadow>
            <vs-list-item
              class="w-full mt-2"
              :subtitle="item.frase"
              v-for="(item, index) in (frases.filter(x => x.tipo == 1))"
              v-bind:key="index"
            >
              <div class="flex w-full">
                <ModalEditarFrase type="naoExec" :frase="item" @update="getFrases" />
              </div>
            </vs-list-item>
            </vx-card>
          </div>

        </vs-tab>
        <vs-tab icon-pack="feather" icon="icon-x-square" label="Nao executada">
          <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
            <vx-card no-shadow>
            <vs-list-item
              class="w-full mt-2"
              :subtitle="item.frase"
              v-for="(item, index) in (frases.filter(x => x.tipo == 2))"
              v-bind:key="index"
            >
              <div class="flex w-full">
                <ModalEditarFrase type="naoExec" :frase="item" @update="getFrases" />
              </div>
            </vs-list-item>
            </vx-card>
          </div>
        </vs-tab>
        <vs-tab icon-pack="feather" icon="icon-slash" label="Declinio">
          <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
            <vx-card no-shadow >
            <vs-list-item
              class="w-full mt-2"
              :subtitle="item.frase"
              v-for="(item, index) in (frases.filter(x => x.tipo == 3))"
              v-bind:key="index"
            >
              <div class="flex w-full">
                <ModalEditarFrase type="naoExec" :frase="item" @update="getFrases" />
              </div>
            </vs-list-item>
            </vx-card>
          </div>
        </vs-tab>
      </vs-tabs>

  </div>
</template>

<script>
import ModalCadastroFrase from "./cadastro.vue";
import ModalEditarFrase from "./editar.vue";

export default {
  data() {
    return {
      frases: []
    };
  },
  methods: {
    async getFrases() {
      this.$vs.loading();
      try {
        this.frases = await this.$http.get(`frase`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.$vs.loading.close();
      }
    }
  },
  components: {
    ModalCadastroFrase,
    ModalEditarFrase
  },
  mounted() {
    this.getFrases();
  }
};
</script>

<style>
.con-slot-tabs {
  width: 100%;
}
.con-tab-ejemplo {
  width: 100%;
}

.vs-list--item .list-titles .vs-list--subtitle {
    font-size: 1.3rem;
}

.el-tabs__item.is-active {
  color: #ffca00 !important;
}

.el-tabs__item:hover {
  color: #ffca00 !important;
}

.el-tabs__active-bar {
  background-color: #ffca00 !important;
}
.col {
  width: auto;
}
.vs-tabs--content {
  padding: 0;
}
</style>
